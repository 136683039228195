import React, { useState } from "react";
import {
  CircularProgress,
  Box,
  Button,
  Typography,
  Container,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AuthInput from "../commponents/AuthInput.tsx";
import AppDialog from "../commponents/AppDialouge.tsx";
import { useDispatch } from "react-redux";
import { signIn } from "../services/auth/services.ts";
import { userActions } from "../redux/user/slice.ts";
import images from "../constant/images.ts";
import { isValidEmail } from "../utils/helpers.ts";
import { useNavigate } from "react-router-dom";

const userInitialData = {
  email: "",
  password: "",
};

const initialError = {
  errorEmail: "",
  errorPassword: "",
};

const SignIn = () => {
  const theme = useTheme();
  const [userData, setUserData] = useState(userInitialData);
  const [secureTextEntry, setSecureTextEntry] = useState(false);
  const [responseError, setResponseError] = useState("");
  const [inputError, setInputError] = useState(initialError);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOnChange = (name: string, value: string) => {
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const handleSubmit = () => {
    let isError = false;
    let errorText = { ...initialError };

    if (!isValidEmail(userData.email)) {
      errorText.errorEmail = "Email is Invalid";
      isError = true;
    }

    if (userData.password.length < 6) {
      errorText.errorPassword =
        "The password should have a minimum length of 6 characters";
      isError = true;
    }

    if (isError) {
      setInputError(errorText);
      return;
    } else {
      setLoading(true);
      signIn(userData.email, userData.password, {})
        .then((response) => {
          // Handle the response as before
          dispatch(
            userActions.setUser({
              customerName: response.data.customerName,
              customerId: response.data.customerId,
              isLoggedIn: true,
              customerEmail: response.data.customerEmail,
              phoneno: response.data.phoneno,
              accessToken: response.data.accessToken || "asdsad",
            })
          );
          navigate("/");
        })
        .catch((err) => {
          alert("The username or password is incorrect");
        })
        .finally(() => setLoading(false));
    }
  };

  // Styles object
  const styles = {
    container: {
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#f0f2f5",
    },
    gridContainer: {
      backgroundColor: "white",
      borderRadius: 8,
      boxShadow: 1,
      p: 4,
      width: "100%",
      maxWidth: 800, // Adjusted width to accommodate side-by-side layout
    },
    leftGrid: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 2,
    },
    logo: {
      width: 100,
      height: "auto", // Set height to auto to maintain aspect ratio
    },
    welcomeText: {
      fontWeight: "bold",
      letterSpacing: 1,
      marginBottom: 1,
      fontFamily: "Spectral, serif",
    },
    infoText: {
      fontFamily: "Spectral, serif",
      letterSpacing: 1,
    },
    rightGrid: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 2,
    },
    authInput: {
      width: "100%",
      height: 48,
      mb: 2,
      borderRadius: 1,
    },
    submitButton: {
      width: "100%",
      height: 48,
      borderRadius: 1,
      backgroundColor: "#b7472a",
      "&:hover": {
        backgroundColor: "#8E2B1E",
      },
    },
    footerText: {
      textAlign: "center",
      mt: 2,
      fontFamily: "Spectral, serif",
    },
    forgotPasswordButton: {
      color: "#092238",
      fontWeight: "bold",
      fontFamily: "Spectral, serif",
      letterSpacing: 1,
    },
    createAccountButton: {
      letterSpacing: 1,
      backgroundColor: "grey",
      color: "white",
      fontWeight: "400",
      paddingX: "18px",
      fontFamily: "Spectral, serif",
      borderRadius: 1,
      "&:hover": {
        backgroundColor: "darkgrey",
      },
    },
  };

  return (
    <Container sx={styles.container}>
      <Grid container sx={styles.gridContainer}>
        {/* Left Side Grid for Logo and Welcome Message */}
        <Grid item xs={12} md={6} sx={styles.leftGrid}>
          <img src={images.logo} alt="Logo" style={styles.logo} />
          <Typography variant="h5" sx={styles.welcomeText}>
            Welcome
          </Typography>
          <Typography variant="body2" sx={styles.infoText}>
            Please login or signup to continue to our Web
          </Typography>
        </Grid>

        {/* Right Side Grid for Input Fields */}
        <Grid item xs={12} md={6} sx={styles.rightGrid}>
          <AuthInput
            label="Email"
            onChange={(e) => handleOnChange("email", e.target.value)}
            value={userData.email}
            errorMessage={inputError.errorEmail}
            sx={styles.authInput}
          />
          <AuthInput
            label="Password"
            onChange={(e) => handleOnChange("password", e.target.value)}
            value={userData.password}
            secureTextEntry={!secureTextEntry}
            setSecureTextEntry={() => setSecureTextEntry(!secureTextEntry)}
            errorMessage={inputError.errorPassword}
            sx={styles.authInput}
          />
          <Button
            variant="contained"
            sx={styles.submitButton}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Sign In"
            )}
          </Button>
          <Box sx={styles.footerText}>
            <Typography variant="body2">
              <Button
                onClick={() => navigate("/forgot-password")}
                sx={styles.forgotPasswordButton}
              >
                Forgot your password?
              </Button>
            </Typography>
            <Typography variant="body2">
              <Button
                onClick={() => navigate("/signup")}
                sx={styles.createAccountButton}
              >
                create an account
              </Button>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <AppDialog
        visible={!!responseError}
        onClose={() => setResponseError("")}
      />
    </Container>
  );
};

export default SignIn;
