import React, { useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  useTheme,
  IconButton,
  Card,
  Grid,
  Container,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { StoreDispatch, StoreState } from "../redux/reduxStore.ts";
import { addOrder } from "../services/orders/services.ts";
import { cartActions, cartSliceIntialState } from "../redux/cart/slice.ts";
import { useNavigate } from "react-router-dom";
import OrderSummaryCard from "../commponents/OrderSummaryCard.tsx";
import AppHeading from "../commponents/AppHeading.tsx";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Header from "../commponents/Header.tsx";
import Footer from "../commponents/Footer.tsx";

const CheckOut: React.FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const cartData = useSelector((state: StoreState) => state.cart);
  const userData = useSelector((state: StoreState) => state.user);
  const dispatch = useDispatch<StoreDispatch>();

  const handlePlaceOrder = () => {
    if (userData.accessToken) {
      addOrder(
        {
          customerId: userData.customerId,
          isCancelled: false,
          orderId: "D3B38D69-0124-489C-A107-EAA88F276794",
          paymentId: "D3B38D69-0124-489C-A107-EAA88F276794",
          totalAmount:
            cartData.total + cartData.total * 0.079 + cartData.total * 0.12,
          orderStatus: "UnPaid",
          createDate: new Date().toISOString(),
          createBy: userData.customerName,
          orderItems: cartData.cartProducts.map((item) => ({
            orderItemId: "D3B38D69-0124-489C-A107-EAA88F276794",
            orderId: "D3B38D69-0124-489C-A107-EAA88F276794",
            productId: String(item.productData.productId),
            quantity: item.quantity,
            price: item.productData.price,
            rowState: 1,
          })),
        },
        {}
      ).then((data: any) => {
        setVisible(true);
        if (data?.httpStatusCode === 200) {
          dispatch(cartActions.setCart(cartSliceIntialState));
        }
      });
    } else {
      navigate("/signIn");
    }
  };

  // Internal styles
  const styles = {
    container: {
      backgroundColor: theme.palette.background.default,
      minHeight: "100vh",
      overflow: "hidden",
    },
    header: {
      backgroundColor: "#b7472a",
      width: "100%",
      height: 90,
      color: "white",

      boxShadow: theme.shadows[3],
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "relative",
      paddingX: 2,
    },
    iconButton: {
      position: "absolute",
      left: 16,
    },
    orderCard: {
      fontFamily: "Spectral, serif",
      display: "flex",
      marginBottom: 2,
      overflow: "hidden",
    },
    totalSummaryCard: {
      padding: 2,
      borderRadius: 2,
      boxShadow: theme.shadows[2],
      backgroundColor: theme.palette.background.paper,
      height: "auto",
    },
    totalSummary: {
      display: "flex",
      justifyContent: "space-between",
      paddingY: 1,
    },
    fixedFooter: {
      backgroundColor: theme.palette.background.paper,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      mt: 5,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    dialogTitle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      marginBottom: 2,
    },
    dialogIcon: {
      fontSize: 80,
      color: "#b7472a",
      marginBottom: 2,
    },
    dialogContent: {
      color: theme.palette.text.primary,
      textAlign: "center",
    },
    continueButton: {
      width: 200,
      height: 50,
      margin: "auto",
      backgroundColor: '"#b7472a"',
    },
  };

  return (
    <>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <IconButton
            edge="start"
            color="inherit"
            sx={styles.iconButton}
            onClick={() => navigate("/cart")}
          >
            <ArrowBackIcon sx={{ color: "white", fontSize: 25 }} />
          </IconButton>

          <AppHeading heading="Order Summary" viewAll={false} />
        </Box>

        <Box px={3} pb={15} mt={6} fontFamily="Spectral, serif">
          <Container sx={{ overflow: "auto" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                {cartData.cartProducts.map((item, index) => (
                  <Card key={index} sx={styles.orderCard} elevation={0}>
                    <OrderSummaryCard item={item} />
                  </Card>
                ))}
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={styles.fixedFooter}>
                  <Button
                    variant="contained"
                    onClick={handlePlaceOrder}
                    sx={{
                      width: 150,
                      height: 50,
                      fontFamily: "Spectral, serif",
                      backgroundColor: "#b7472a",
                      borderRadius: 1,
                      mt: 1,
                      "&:hover": {
                        backgroundColor: "#8E2B1E",
                      },
                    }}
                  >
                    Place Order
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Dialog
          open={visible}
          onClose={() => setVisible(false)}
          sx={{ borderRadius: "20px" }}
        >
          <DialogTitle>
            <Box sx={styles.dialogTitle}>
              <ShoppingBagIcon sx={styles.dialogIcon} />
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#b7472a" }}
              >
                Order Placed Successfully!
              </Typography>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography variant="body2" sx={styles.dialogContent}>
              Your order has been placed successfully and is ready for pickup.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={() => navigate("/home")}
              sx={[
                styles.continueButton,
                {
                  backgroundColor: "#b7472a",
                },
              ]}
            >
              Continue Shopping
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default CheckOut;
