import { Provider } from "react-redux";
import "./App.css";
import reduxStore, { persistor } from "./redux/reduxStore.ts";
import { PersistGate } from "redux-persist/integration/react";
import Layout from "./view/Layout.tsx";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import Header from "./commponents/Header.tsx";

function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in ms
      once: true, // Whether animation should happen only once
    });
  }, []);
  return (
    <div className="App">
      <Provider store={reduxStore}>
        <PersistGate loading={null} persistor={persistor}>
          <Layout />
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
